import { startSubmit, stopSubmit } from 'redux-form'
import { browserHistory } from 'react-router'

import {
  createClient,
  currentUserSelector,
  hydrateAuthentication
} from 'auth'
import { openModal } from 'modal'
import { discountCodesSelector } from 'order-summary'
import { login } from 'registration/actions'
import {
  createStripeChargeToken,
  billingAddressSelector,
  handlePaymentError
} from 'stripe'
import { invoiceRecipientSelector } from 'invoice-request-form'

import ConfirmModal from './ConfirmModal'
import { INDIVIDUAL_RESERVATION_FORM_NAME } from '../constants'
import { invoiceRequestedSelector, getIndividualReservationOrderSummary, registrationFormSelector } from './selectors'
import { CLEAR_USER_REGISTRATION_ERROR, SET_USER_REGISTRATION_ERROR } from './types'
import { BadRequestError } from 'api'

export const reserve = (props) => (dispatch, getState) => {
  const stateWhenSubmitting = getState()
  const currentUser = currentUserSelector(stateWhenSubmitting)
  const registrationInfo = registrationFormSelector(INDIVIDUAL_RESERVATION_FORM_NAME)(stateWhenSubmitting) // for new user if creating new user during session sale sign up
  const formAddress = billingAddressSelector(INDIVIDUAL_RESERVATION_FORM_NAME)(stateWhenSubmitting)
  const discountCodes = discountCodesSelector(INDIVIDUAL_RESERVATION_FORM_NAME)(stateWhenSubmitting)
  const orderSummary = getIndividualReservationOrderSummary(stateWhenSubmitting, props)
  const invoiceRequested = invoiceRequestedSelector(stateWhenSubmitting)
  const invoiceRecipient = invoiceRecipientSelector(INDIVIDUAL_RESERVATION_FORM_NAME)(stateWhenSubmitting)

  dispatch({ type: CLEAR_USER_REGISTRATION_ERROR })
  dispatch(startSubmit(INDIVIDUAL_RESERVATION_FORM_NAME))

  let registerPromise = Promise.resolve()
  let newMembershipCreated = false
  if (!currentUser &&
    registrationInfo.registration_first_name &&
    registrationInfo.registration_last_name &&
    registrationInfo.registration_email &&
    registrationInfo.registration_password
  ) {
    registerPromise = createClient(stateWhenSubmitting).post('/v2/users', {
      body: {
        first_name: registrationInfo.registration_first_name,
        last_name: registrationInfo.registration_last_name,
        email: registrationInfo.registration_email,
        password: registrationInfo.registration_password,
        institution_id: stateWhenSubmitting?.individualReservations?.selectedInstitution?.id,
        session_sale_id: props?.sessionSale?.id
      }
    })
      .then(() => {
        newMembershipCreated = true
        return dispatch(login(registrationInfo.registration_email, registrationInfo.registration_password))
      })
  }

  return registerPromise.then(() => {
    const currentUser = currentUserSelector(getState())
    const client = createClient(getState())

    const updatePromises = [
      Promise.resolve(
        orderSummary.total > 0 && !invoiceRequested
          ? createStripeChargeToken(props.stripeElements.cardNumber)
          : null
      )
        .then(chargeToken => {
          const body = {
            type: 'user',
            newMembershipCreated
          }

          if (chargeToken) {
            body.stripe_charge_token = chargeToken.id
            body.invoice_recipient = {
              address_line_1: formAddress.address_line_1,
              address_line_2: formAddress.address_line_2,
              // we're not capturing name or email in this form so get those from the user's account
              billing_name: currentUser.name,
              billing_email: currentUser.email,
              city: formAddress.city,
              country: formAddress.country,
              institution: formAddress.institution,
              postal_code: formAddress.postal_code,
              state: formAddress.state
            }
          }

          if (invoiceRequested && orderSummary.total > 0) {
            body.invoice_recipient = invoiceRecipient
          }

          if (discountCodes) {
            body.discount_codes = discountCodes
          }

          return client.post(`/v2/session_sales/${props.sessionSale.id}/reservations`, { body })
        })
    ]
    return Promise.all(updatePromises)
      .then(([createReservationResponse]) => {
        dispatch(stopSubmit(INDIVIDUAL_RESERVATION_FORM_NAME))
        return createReservationResponse.json()
          .then(reservation => client.get(`/v2/orders/by-order-item/${reservation.order_item_id}`))
          .then(res => res.json())
          .then(order => {
            dispatch(hydrateAuthentication())
            browserHistory.push(`/sessions/${props.sessionSale.slug}`)
            dispatch(openModal(ConfirmModal, {
              sessionSale: props.sessionSale,
              order,
              invoiceRequested
            }))
          })
      })
      .catch(err => {
        dispatch(handlePaymentError(err, INDIVIDUAL_RESERVATION_FORM_NAME))
      })
  }).catch(err => {
    dispatch(stopSubmit(INDIVIDUAL_RESERVATION_FORM_NAME))
    if (err instanceof BadRequestError) {
      return err.response.json()
        .then(body => {
          if (body?.errors) {
            dispatch({
              type: SET_USER_REGISTRATION_ERROR,
              errors: body.errors
            })
            document.getElementById('session-sale-new-user-registration-form')?.scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
    } else {
      dispatch(handlePaymentError(err, INDIVIDUAL_RESERVATION_FORM_NAME))
    }
  })
}
